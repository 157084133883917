const developersFlexPrices = {
    homeSize: { noVat: null, vat: null },
    homeLevels: { noVat: 77.31, vat: 95.86 },
    tablet: { noVat: 0.00, vat: 0.00 },
    hub: { noVat: 0.00, vat: 0.00 },
    threePhase: { noVat: 66.81, vat: 82.84 },
    singleSwitch: { noVat: 27.51, vat: 34.11 },
    doubleSwitch: { noVat: 32.20, vat: 39.93 },
    tripleSwitch: { noVat: 59.71, vat: 74.04 },
    howMany: { noVat: 0, vat: 0 },
    smartSocket: { noVat: 32.20, vat: 39.93 },
    dimmerSwitch: { noVat: 32.20, vat: 39.93 },
    shutersSwitch: { noVat: 32.20, vat: 39.93 },
    thermostatNumber: { noVat: 89.68, vat: 111.2 },
    boilerSwitch: { noVat: 32.20, vat: 39.93 },
    consumptionRecordSpots: { noVat: 38.37, vat: 47.58 },
    hidden_switchFrame: { noVat: 0.00, vat: 0.00 },
    hidden_consumptionRecord: { noVat: 38.37, vat: 47.58 },
    energyMetering: { noVat: 0.00, vat: 0.00 },
    singleModule: { noVat: 27.51, vat: 34.11 },
    doubleModule: { noVat: 32.20, vat: 39.93 },
    lockModule: { noVat: 32.20, vat: 39.93 },
    alarmModule: { noVat: 32.20, vat: 39.93 },
    onePhase: { noVat: 48.36, vat: 59.97 }
};
export default developersFlexPrices;