const standardPacket = {
    homeLevels: 0,
    singleSwitch: 0,
    doubleSwitch: 0,
    tripleSwitch: 0,
    hidden_switchFrame: 0,
    smartSocket: 0,
    dimmerSwitch: 0,
    shutersSwitch: 0,
    thermostatNumber: 0,
    boilerSwitch: 0,
    lockModule: 0,
    // alarmModule: 0,
    // onePhase: 0,
    // threePhase: 0,
    hidden_consumptionRecord: 0,
};

export default standardPacket;
