import React from 'react';
import { Outlet, Link } from 'react-router-dom';

const RouteLayout = () => {
    return (
        <>
            <nav>
                <Link to="/"></Link>
                <Link to="/SelectPacket"></Link>
                <Link to="/SwitchOrModulePacket"></Link>
            </nav>
            <Outlet />
        </>
    );
}

export default RouteLayout;
