import { Document, Packer, Paragraph, TextRun, ImageRun, SectionType, AlignmentType, UnderlineType, Table, TableRow, TableCell, WidthType, BorderStyle, ExternalHyperlink, Footer, Header } from "docx";
import { saveAs } from "file-saver";
import hmLogo from '../images/housemateLogo.png';

const getImageBuffer = async (url) => {
    const response = await fetch(url);
    if (!response.ok) {
        throw new Error('Network response was not ok ' + response.statusText);
    }
    return await response.arrayBuffer();
};

function formatNumberWithThousandSeparator(value) {

    const numberValue = Number(value);

    return numberValue
        .toFixed(2)
        .replace('.', ',')
        .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
}

export const generateDocument = async (props) => {
    function printOutUserChoices(key) {
        const { extras, hiddenDevicesSingle, hiddenDevicesDouble, devicePrice, choicesDesc, nextComponentModule } = props;

        const formatPrice = (quantity, price) => `${quantity}*${formatNumberWithThousandSeparator(price.noVat)}€`;

        if (key === "hidden_switchFrame") {
            if (hiddenDevicesDouble === 0 && hiddenDevicesSingle) {
                return `${hiddenDevicesSingle} Μονό module για διακόπτη πλαισίου`;
            } else if (hiddenDevicesSingle === 0) {
                return hiddenDevicesDouble < 2
                    ? `${hiddenDevicesDouble} Διπλό module για διακόπτη πλαισίου`
                    : `${hiddenDevicesDouble} Διπλά modules για διακόπτες πλαισίου (${formatPrice(hiddenDevicesDouble, devicePrice["doubleModule"])})`;
            } else {
                return hiddenDevicesDouble < 2
                    ? `${hiddenDevicesSingle} Μονό και ${hiddenDevicesDouble} διπλό module για διακόπτη πλαισίου (${formatPrice(hiddenDevicesSingle, devicePrice["singleModule"])} + ${formatPrice(hiddenDevicesDouble, devicePrice["doubleModule"])})`
                    : `${hiddenDevicesSingle} Μονό και ${hiddenDevicesDouble} διπλά modules για διακόπτες πλαισίου (${formatPrice(hiddenDevicesSingle, devicePrice["singleModule"])} + ${formatPrice(hiddenDevicesDouble, devicePrice["doubleModule"])})`;
            }
        }

        if (nextComponentModule && key === "tripleSwitch") {
            if (extras[key] > 1) {
                return `${extras[key]} modules για τριπλό διακόπτη (${formatPrice(extras[key], devicePrice["singleModule"])} + ${formatPrice(extras[key], devicePrice["doubleModule"])})`
            }
            else {
                return `${extras[key]} module για τριπλό διακόπτη (${formatPrice(extras[key], devicePrice["singleModule"])} + ${formatPrice(extras[key], devicePrice["doubleModule"])})`
            }
        }

        if (extras[key] > 1) {
            return `${extras[key]} ${choicesDesc[key].multiple} (${formatPrice(extras[key], devicePrice[key])})`;
        } else {
            return key === "onePhase" || key === "threePhase"
                ? choicesDesc[key].single
                : `${extras[key]} ${choicesDesc[key].single}`;
        }
    }

    console.log("_vatValue:", props.vatValue);

    try {
        const imageBuffer = await getImageBuffer(hmLogo);

        const dynamicRows = Object.keys(props.choicesDesc)
            .filter(key => Object.keys(props.extras).includes(key))
            .map(key => {
                return new TableRow({
                    children: [
                        new TableCell({
                            borders: {
                                top: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
                                bottom: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
                                left: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
                                right: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
                            },
                            children: [
                                new Paragraph({
                                    children: [
                                        new TextRun({
                                            text: printOutUserChoices(key),
                                            font: "Arial",
                                            size: 20,
                                            color: "000000"
                                        })
                                    ]
                                })
                            ],
                            width: {
                                size: 100,
                                type: WidthType.PERCENTAGE,
                            }
                        }),
                        new TableCell({
                            borders: {
                                top: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
                                bottom: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
                                left: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
                                right: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
                            },
                            children: [new Paragraph({
                                children: [
                                    new TextRun({
                                        text: (key === "hidden_switchFrame" ? new Intl.NumberFormat('de-DE', {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2
                                        }).format(parseFloat(props.devicePrice[key].noVat)) + "€" : new Intl.NumberFormat('de-DE', {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2
                                        }).format(parseFloat(props.extras[key] * props.devicePrice[key].noVat)) + "€"),
                                        font: "Arial",
                                        size: 20,
                                        color: "000000"
                                    })
                                ],
                                alignment: AlignmentType.RIGHT,
                            })],
                            width: {
                                size: 100,
                                type: WidthType.PERCENTAGE,
                            }
                        }),
                    ],
                    width: {
                        size: 100,
                        type: WidthType.PERCENTAGE,
                    }
                });
            });

        const hyperlink = new ExternalHyperlink({
            link: "http://www.entranet.gr",
            children: [
                new TextRun({
                    text: "www.entranet.gr",
                    bold: true,
                    font: "Arial",
                    size: 16,
                    color: "000000"
                }),
            ],
        });

        const doc = new Document({
            compatibility: {
                version: 15,
                doNotExpandShiftReturn: true
            },
            background: {
                color: "F1F1F1",
            },
            sections: [
                {
                    properties: {
                        type: SectionType.CONTINUOUS
                    },
                    headers: {
                        default: new Header({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.RIGHT,
                                    children: [
                                        new ImageRun({
                                            data: imageBuffer,
                                            transformation: {
                                                width: 355,
                                                height: 70
                                            }
                                        }),
                                    ],
                                }),
                            ],
                        }),
                    },
                    children: [
                        new Paragraph({ alignment: AlignmentType.RIGHT }),
                        new Paragraph({
                            alignment: AlignmentType.RIGHT,
                            children: [
                                new TextRun({ text: "ENTRANET ΕΠΕ", font: "Arial", size: 16, bold: true, color: "000000" }),
                                new TextRun({ break: 1 }),
                                new TextRun({ text: "ΤΕΧΝΟΛΟΓΙΚΕΣ ΕΦΑΡΜΟΓΕΣ", font: "Arial", size: 16, bold: true, color: "000000" }),
                                new TextRun({ break: 1 }),
                                new TextRun({ text: "ΑΦΜ:998100820", font: "Arial", size: 16, bold: true, color: "000000" }),
                                new TextRun({ break: 1 }),
                                new TextRun({ text: "ΔΟΥ: Ζ' ΘΕΣΣΑΛΟΝΙΚΗΣ", font: "Arial", size: 16, bold: true, color: "000000" }),
                                new TextRun({ break: 1 })
                            ],
                        }),
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                                new TextRun({ text: props.docxDate, bold: true, size: 20, font: "Arial", color: "000000" }),
                                new TextRun({ break: 2 }),
                                new TextRun({ text: "PROFORMA INVOICE NO:23AK0508", font: "Arial", size: 20, bold: true, underline: { type: UnderlineType.SINGLE }, color: "000000" }),
                                new TextRun({ break: 2 }),
                                new TextRun({ text: "Lefteris Papageorgiou", font: "Arial", size: 16, bold: true, color: "000000" }),
                                new TextRun({ break: 1 }),
                                new TextRun({ text: "Διεύθυνση: Νεμέας 27,Χαριλάου Θεσσαλονίκη", font: "Arial", size: 16, bold: true, color: "000000" }),
                                new TextRun({ break: 1 }),
                                new TextRun({ text: "Τηλέφωνο:2310123456", font: "Arial", size: 16, bold: true, color: "000000" }),
                                new TextRun({ break: 1 }),
                                new TextRun({ text: "Email:", font: "Arial", bold: true, size: 16, color: "000000" }),
                                new TextRun({ text: " test@email.com", font: "Arial", size: 16, color: "000000" }),
                                new TextRun({ break: 1 }),
                            ],
                        }),
                        new Table({
                            borders: {
                                bottom: { size: 0 },
                                right: { size: 0 },
                                left: { size: 0 }
                            },
                            width: {
                                size: 100,
                                type: WidthType.PERCENTAGE,
                            },
                            rows: [
                                new TableRow({
                                    children: [
                                        new TableCell({
                                            borders: {
                                                bottom: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
                                                left: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
                                                right: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
                                            },
                                            children: [new Paragraph({
                                                children: [
                                                    new TextRun({
                                                        text: `1 ${props.packageDesc}`,
                                                        bold: true,
                                                        font: "Arial",
                                                        size: 20,
                                                        color: "000000"
                                                    }),
                                                    new TextRun({
                                                        break: 1,
                                                        text: props.contentDesc,
                                                        font: "Arial",
                                                        size: 20,
                                                        color: "000000"
                                                    })
                                                ],
                                                width: {
                                                    size: 100,
                                                    type: WidthType.PERCENTAGE,
                                                }
                                            })],
                                        }),
                                        new TableCell({
                                            borders: {
                                                bottom: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
                                                left: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
                                                right: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
                                            },
                                            children: [new Paragraph({
                                                children: [
                                                    new TextRun({
                                                        text: `${(formatNumberWithThousandSeparator(props.packagePriceNoVat))}€`,
                                                        font: "Arial",
                                                        size: 20,
                                                        color: "000000"
                                                    })
                                                ],
                                                alignment: AlignmentType.RIGHT
                                            })],
                                            width: {
                                                size: 100,
                                                type: WidthType.PERCENTAGE,
                                            }
                                        }),
                                    ],
                                    width: {
                                        size: 100,
                                        type: WidthType.PERCENTAGE,
                                    }
                                }),
                                ...dynamicRows,
                                new TableRow({
                                    children: [
                                        new TableCell({
                                            borders: {
                                                top: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
                                                bottom: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
                                                left: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
                                                right: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
                                            },
                                            children: [new Paragraph({
                                                children: [
                                                    new TextRun({
                                                        // break: 1,
                                                        text: "ΣΥΝΟΛΟ (χωρίς ΦΠΑ)",
                                                        font: "Arial",
                                                        size: 20,
                                                        color: "000000",
                                                        width: {
                                                            size: 100,
                                                            type: WidthType.PERCENTAGE,
                                                        }
                                                    })
                                                ]
                                            })],
                                            width: {
                                                size: 100,
                                                type: WidthType.PERCENTAGE,
                                            }
                                        }),
                                        new TableCell({
                                            borders: {
                                                top: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
                                                bottom: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
                                                left: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
                                                right: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
                                            },
                                            children: [
                                                new Paragraph({
                                                    children: [
                                                        new TextRun({
                                                            // break: 1,
                                                            text: `${props.finalPriceNoVat}€`,
                                                            font: "Arial",
                                                            size: 20,
                                                            color: "000000"
                                                        })
                                                    ],
                                                    alignment: AlignmentType.RIGHT
                                                })
                                            ],
                                            width: {
                                                size: 100,
                                                type: WidthType.PERCENTAGE,
                                            }
                                        }),
                                    ],
                                    width: {
                                        size: 100,
                                        type: WidthType.PERCENTAGE,
                                    }
                                }),
                                new TableRow({
                                    children: [
                                        new TableCell({
                                            borders: {
                                                top: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
                                                bottom: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
                                                left: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
                                                right: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
                                            },
                                            children: [new Paragraph({
                                                children: [
                                                    new TextRun({
                                                        text: "ΦΠΑ",
                                                        font: "Arial",
                                                        size: 20,
                                                        color: "000000"
                                                    })
                                                ]
                                            })],
                                            width: {
                                                size: 100,
                                                type: WidthType.PERCENTAGE,
                                            }
                                        }),
                                        new TableCell({
                                            borders: {
                                                top: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
                                                bottom: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
                                                left: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
                                                right: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
                                            },
                                            children: [
                                                new Paragraph({
                                                    children: [
                                                        new TextRun({
                                                            text: `${formatNumberWithThousandSeparator(props.vatValue)}€`,
                                                            font: "Arial",
                                                            size: 20,
                                                            color: "000000"
                                                        })
                                                    ],
                                                    alignment: AlignmentType.RIGHT
                                                })
                                            ],
                                            width: {
                                                size: 100,
                                                type: WidthType.PERCENTAGE,
                                            }
                                        }),
                                    ],
                                    width: {
                                        size: 100,
                                        type: WidthType.PERCENTAGE,
                                    }
                                }),
                                new TableRow({
                                    children: [
                                        new TableCell({
                                            borders: {
                                                top: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
                                                bottom: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
                                                left: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
                                                right: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
                                            },
                                            shading: {
                                                fill: "ff8000"
                                            },
                                            children: [
                                                new Paragraph({
                                                    children: [
                                                        new TextRun({
                                                            text: "Total",
                                                            bold: true,
                                                            color: "FFFFFF",
                                                            font: "Arial",
                                                            size: 20,
                                                        })
                                                    ],
                                                })
                                            ],
                                            width: {
                                                size: 100,
                                                type: WidthType.PERCENTAGE,
                                            }
                                        }),
                                        new TableCell({
                                            borders: {
                                                top: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
                                                bottom: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
                                                left: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
                                                right: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
                                            },
                                            shading: {
                                                fill: "ff8000",
                                            },
                                            children: [
                                                new Paragraph({
                                                    children: [
                                                        new TextRun({
                                                            text: `${props.finalPriceVat}€`,
                                                            color: "FFFFFF",
                                                            font: "Arial",
                                                            bold: true,
                                                            size: 20,
                                                        })
                                                    ],
                                                    alignment: AlignmentType.RIGHT
                                                })
                                            ],
                                            width: {
                                                size: 100,
                                                type: WidthType.PERCENTAGE,
                                            }
                                        }),
                                    ],
                                    width: {
                                        size: 100,
                                        type: WidthType.PERCENTAGE,
                                    }
                                }),
                            ]
                        }),
                        new Table({
                            rows: [
                                new TableRow({
                                    children: [
                                        new TableCell({
                                            columnSpan: 2,
                                            borders: {
                                                top: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
                                                bottom: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
                                                left: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
                                                right: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
                                            },
                                            children: [
                                                new Paragraph({
                                                    alignment: AlignmentType.LEFT,
                                                    children: [
                                                        new TextRun({ text: "PAYMENT TERMS: IN ADVANCE", size: 20, break: 1, font: "Arial", color: "000000" })
                                                    ],
                                                })
                                            ],
                                            width: {
                                                size: 100,
                                                type: WidthType.PERCENTAGE,
                                            }
                                        }),
                                    ],
                                    width: {
                                        size: 100,
                                        type: WidthType.PERCENTAGE,
                                    }
                                }),
                                new TableRow({
                                    children: [
                                        new TableCell({
                                            borders: {
                                                top: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
                                                bottom: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
                                                left: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
                                                right: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
                                            },
                                            children: [
                                                new Paragraph({
                                                    alignment: AlignmentType.LEFT,
                                                    children: [
                                                        new TextRun({ text: "", size: 12, color: "000000" }),
                                                        new TextRun({ break: 1 }),
                                                        new TextRun({ text: "Στην τιμή δεν συμπεριλαμβάνεται η μεταφορά των υλικών", size: 20, font: "Arial", color: "000000" })
                                                    ],
                                                })
                                            ],
                                            width: {
                                                size: 100,
                                                type: WidthType.PERCENTAGE,
                                            }
                                        }),
                                    ],
                                    width: {
                                        size: 100,
                                        type: WidthType.PERCENTAGE,
                                    }
                                }),
                            ],
                            width: {
                                size: 100,
                                type: WidthType.PERCENTAGE,
                            }
                        }),
                    ],
                    footers: {
                        default: new Footer({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.RIGHT,
                                    keepWithNext: true,
                                    children: [
                                        new TextRun({ text: "Τραπεζικός λογαριασμός:", bold: true, alignment: AlignmentType.RIGHT, font: "Arial Black", size: 16, color: "000000" }),
                                        new TextRun({ break: 1 }),
                                        new TextRun({ text: "", size: 16, color: "000000" }),
                                        new TextRun({ break: 1 }),
                                        new TextRun({ text: "ΤΡΑΠΕΖΑ ΠΕΙΡΑΙΩΣ", bold: true, alignment: AlignmentType.RIGHT, font: "Arial", size: 16, color: "000000" }),
                                        new TextRun({ break: 1 }),
                                        new TextRun({ text: "ΑΡ ΛΟΓΑΡΙΑΣΜΟΥ:", bold: true, font: "Arial", size: 16, color: "000000" }),
                                        new TextRun({ text: "1234-123456-123", font: "Arial", size: 16, color: "000000" }),
                                        new TextRun({ break: 1 }),
                                        new TextRun({ text: "IBAN:", bold: true, font: "Arial", size: 16, color: "000000" }),
                                        new TextRun({ text: "GR00 1234-123456-123", font: "Arial", size: 16, color: "000000" }),
                                        new TextRun({ break: 1 }),
                                        new TextRun({ text: "BIC/SWIFT:", bold: true, font: "Arial", size: 16, color: "000000" }),
                                        new TextRun({ text: "GR00 1234-123456-123", font: "Arial", size: 16, color: "000000" }),
                                    ],

                                }),
                                new Paragraph({ break: 1 }),
                                new Paragraph({
                                    border: {
                                        top: { style: BorderStyle.THICK, size: 1, color: "000000" },
                                    },
                                    indent: {
                                        left: 6940
                                    },
                                    children: [],
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.RIGHT,
                                    children: [
                                        new TextRun({ text: "info", bold: true, font: "Arial Black", size: 16, color: "000000" }),
                                        new TextRun({ break: 1 }),
                                        new TextRun({ text: "", size: 16, color: "000000" }),
                                        new TextRun({ break: 1 }),
                                        new TextRun({ text: "Πλαταιών 44, 542 49", bold: false, font: "Arial", size: 16, color: "000000" }),
                                        new TextRun({ break: 1 }),
                                        new TextRun({ text: "Θεσσαλονίκη", bold: true, font: "Arial", size: 16, color: "000000" }),
                                        new TextRun({ break: 1 }),
                                        new TextRun({ text: "T: 0030 2310 300 050", bold: false, font: "Arial", size: 16, color: "000000" }),
                                        new TextRun({ break: 1 }),
                                        new TextRun({ text: "F: 0030 2310 300 057", bold: false, font: "Arial", size: 16, color: "000000" }),
                                        new TextRun({ break: 2 }),
                                        new TextRun({ text: "E-mail: ", bold: true, font: "Arial", size: 16, color: "000000" }),
                                        new TextRun({ text: "info@entranet.gr", font: "Arial", size: 16, color: "000000" }),
                                        new TextRun({ break: 1 }),
                                        hyperlink
                                    ],
                                }),
                            ],
                        }),
                    },
                },
            ],
        });

        const blob = await Packer.toBlob(doc);
        saveAs(blob, "housemate PI _____.docx");
    } catch (error) {
        console.error('Error generating document: ', error);
    }
};
