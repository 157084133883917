const installerStandardPrices = {
    homeSize: { noVat: null, vat: null },
    homeLevels: { noVat: 74.19, vat: 92.0 },///
    tablet: { noVat: 0.00, vat: 0.00 },
    hub: { noVat: 0.00, vat: 0.00 },
    onePhase: { noVat: 46.77, vat: 57.99 },//??
    threePhase: { noVat: 64.52, vat: 80 },//??
    singleSwitch: { noVat: 31.90, vat: 39.56 },///
    doubleSwitch: { noVat: 35.61, vat: 44.16 },///
    tripleSwitch: { noVat: 44.52, vat: 55.2 },///
    howMany: { noVat: 0, vat: 0 },
    smartSocket: { noVat: 35.61, vat: 44.16 },///
    dimmerSwitch: { noVat: 44.52, vat: 55.2 },///
    shutersSwitch: { noVat: 44.52, vat: 55.2 },///
    thermostatNumber: { noVat: 86.06, vat: 106.71 },
    boilerSwitch: { noVat: 44.52, vat: 55.2 },///
    consumptionRecordSpots: { noVat: 36.29, vat: 45.0 },//?
    hidden_switchFrame: { noVat: 0.00, vat: 0.00 },
    hidden_consumptionRecord: { noVat: 36.29, vat: 45.0 },//?
    energyMetering: { noVat: 0.00, vat: 0.00 },
    singleModule: { noVat: 24.84, vat: 30.8 },//
    doubleModule: { noVat: 28.52, vat: 35.36 },///
    lockModule: { noVat: 28.52, vat: 35.36 },///
    alarmModule: { noVat: 28.52, vat: 35.36 },///
}
export default installerStandardPrices;