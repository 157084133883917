const bigProjectsStandardPrices = {
    homeSize: { noVat: null, vat: null },
    homeLevels: { noVat: 69.58, vat: 86.28 },
    tablet: { noVat: 0.00, vat: 0.00 },
    hub: { noVat: 0.00, vat: 0.00 },
    onePhase: { noVat: 43.57, vat: 54.03 },
    threePhase: { noVat: 66.81, vat: 82.84 },
    singleSwitch: { noVat: 29.92, vat: 37.1 },
    doubleSwitch: { noVat: 34.79, vat: 43.14 },
    tripleSwitch: { noVat: 41.75, vat: 51.77 },
    howMany: { noVat: 0, vat: 0 },
    smartSocket: { noVat: 34.79, vat: 43.14 },
    dimmerSwitch: { noVat: 41.75, vat: 51.77 },
    shutersSwitch: { noVat: 41.75, vat: 51.77 },
    thermostatNumber: { noVat: 80.71, vat: 100.08 },
    boilerSwitch: { noVat: 41.75, vat: 51.77 },
    consumptionRecordSpots: { noVat: 38.37, vat: 47.58 },
    hidden_switchFrame: { noVat: 0.00, vat: 0.00 },
    hidden_consumptionRecord: { noVat: 38.37, vat: 47.58 },
    energyMetering: { noVat: 0.00, vat: 0.00 },
    singleModule: { noVat: 24.76, vat: 30.7 },
    doubleModule: { noVat: 28.98, vat: 35.94 },
    lockModule: { noVat: 28.98, vat: 35.94 },
    alarmModule: { noVat: 28.98, vat: 35.94 },
};
export default bigProjectsStandardPrices;