const standardPacket = {
    homeLevels: 1,
    tablet: 1,
    hub: 1,
    singleSwitch: 4,
    doubleSwitch: 2,
    smartSocket: 2,
    thermostatNumber: 1
};

export default standardPacket;


