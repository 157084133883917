const meidanisStandardPrices = {
    homeSize: { noVat: null, vat: null },
    homeLevels: { noVat: 55.35, vat: 68.63 },//
    tablet: { noVat: 0.00, vat: 0.00 },//
    hub: { noVat: 0.00, vat: 0.00 },//
    threePhase: { noVat: 47.67, vat: 59.11 },//
    singleSwitch: { noVat: 23.27, vat: 28.85 },//
    doubleSwitch: { noVat: 28.08, vat: 34.82 },//
    tripleSwitch: { noVat: 33.04, vat: 40.97 },//
    howMany: { noVat: 0, vat: 0 },
    smartSocket: { noVat: 28.08, vat: 34.82 },//
    dimmerSwitch: { noVat: 33.04, vat: 40.97 },//
    shutersSwitch: { noVat: 33.04, vat: 40.97 },//
    thermostatNumber: { noVat: 63.38, vat: 78.59 },//
    boilerSwitch: { noVat: 33.04, vat: 40.97 },//
    consumptionRecordSpots: { noVat: 27.38, vat: 33.95 },//
    hidden_switchFrame: { noVat: 0.00, vat: 0.00 },
    hidden_consumptionRecord: { noVat: 27.38, vat: 33.95 },//
    energyMetering: { noVat: 0.00, vat: 0.00 },
    singleModule: { noVat: 19.64, vat: 24.35 },//
    doubleModule: { noVat: 23.00, vat: 28.52 },//
    lockModule: { noVat: 23.00, vat: 28.52 },//
    alarmModule: { noVat: 23.00, vat: 28.52 },//
    onePhase: { noVat: 34.50, vat: 42.78 }//
};
export default meidanisStandardPrices;