const data = {
    homeLevels: 'Πόσα επίπεδα έχει το σπίτι σας;',
    singleSwitch: 'Πόσους μονούς διακόπτες έχετε;',
    doubleSwitch: 'Πόσους διπλούς διακόπτες έχετε;',
    tripleSwitch: 'Πόσους τριπλούς διακόπτες έχετε;',
    switchFrame: 'Διαθέτετε πλαίσια με πολλούς διακόπτες;',
    smartSocket: 'Πόσες πρίζες θέλετε να κάνετε έξυπνες;',
    dimmerSwitch: 'Πόσους διακόπτες dimmers έχετε;',
    shutersSwitch: 'Πόσους διακόπτες χειρισμού ρολών έχετε;',
    thermostatNumber: 'Πόσους θερμοστάτες θα χρειαστείτε;',
    boilerSwitch: 'Πόσους διακόπτες boiler θα χρειαστείτε;',
    lockModule: 'Πόσες ηλεκτρικές κλειδαριές θα χρειαστείτε;',
    alarmModule: 'Θέλετε να ελέγχετε τον συναγερμό;',
    energyRecord: 'Θέλετε να μετράτε την κατανάλωση ενέργειας;',
    consumptionRecord: 'Θέλετε να μετράτε την κατανάλωση σε συγκεκριμένα σημεία (ρευματοδότες);',
};
export default data;