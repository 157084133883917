import React, { useEffect, useState } from 'react';
import axios from 'axios'
import { useLocation } from 'react-router-dom';
import './CSS/twoPackets.css';
import banner from './images/entranet-slider_housemate-2.jpg';
import { ReactComponent as ArrowSvg } from './images/arrow.svg';
import housemateSmartPackage from './images/housemate-smart-home-package-box.jpg';
import housemateFlex from './images/housemate_flex_package3.jpg';
import SwitchOrModulePacket from './SwitchOrModulePacket';
import eurolampStandardPrices from './prices/standard/eurolampStandardPrices';
import meidanisStandardPrices from './prices/standard/meidanisStandardPrices';
import meidanisFlexPrices from './prices/flex/meidanisFlexPrices';
import eurolampFlexPrices from './prices/flex/eurolampFlexPrices';
import storesInGRStandardPrices from './prices/standard/storesInGRStandardPrices';
import storesInGRFlexPrices from './prices/flex/storesInGRFlexPrices';
import housemateCYFlexPrices from './prices/flex/housemateCYFlexPrices';
import developersStandardPrices from './prices/standard/developersStandardPrices';
import developersFlexPrices from './prices/flex/developersFlexPrices';
import bigProjectsStandardPrices from './prices/standard/bigProjectsStandardPrices';
import bigProjectsFlexPrices from './prices/flex/bigProjectsFlexPrices';
import roBgUkFlexPrices from './prices/flex/roBgUkFlexPrices';
import installerStandardPrices from './prices/standard/installerStandardPrices';
import endCustomerStandardPrices from './prices/standard/endCustomerStandardPrices';
import endCustomerFlexPrices from './prices/flex/endCustomerFlexPrices';
import installerFlexPrices from './prices/flex/installerFlexPrices';

function SelectPacket() {
    const location = useLocation();

    const [selectedCatalogue, setSelectedCatalogue] = useState(null);
    const [nextComponentSwitch, setNextComponentSwitch] = useState(false);
    const [nextComponentModule, setNextComponentModule] = useState(false);
    const [currentStandardPrices, setCurrentStandardPrices] = useState({});
    const [currentStandardPriceNoVat, setCurrentStandardPriceNoVat] = useState(0.0);
    const [currentStandardPriceVat, setCurrentStandardPriceVat] = useState(0.0);
    const [currentFlexPrices, setCurrentFlexPrices] = useState({});
    const [currentFlexPriceNoVat, setCurrentFlexPriceNoVat] = useState(0.0);
    const [currentFlexPriceVat, setCurrentFlexPriceVat] = useState(0.0);
    const [clearVat, setClearVat] = useState(0);



    useEffect(() => {
        const catalogue = location.state?.selectedCatalogue;
        if (catalogue) {
            setSelectedCatalogue(catalogue);
            triggerStateUpdate(catalogue);
        }
    }, [location.state]);

    const triggerStateUpdate = (catalogue) => {
        if (catalogue === "eurolamp") {
            console.log("eurolamp!!");

            setCurrentStandardPrices(eurolampStandardPrices);
            setCurrentStandardPriceNoVat(599.0);
            setCurrentStandardPriceVat(742.76);
            setCurrentFlexPrices(eurolampFlexPrices);
            setCurrentFlexPriceNoVat(448.56);
            setCurrentFlexPriceVat(556.21);
        } else if (catalogue === "meidanis") {
            setCurrentStandardPrices(meidanisStandardPrices);
            setCurrentStandardPriceNoVat(625.00);
            setCurrentStandardPriceVat(775.00);
            setCurrentFlexPrices(meidanisFlexPrices);
            setCurrentFlexPriceNoVat(475.00);
            setCurrentFlexPriceVat(589.00);
        } else if (catalogue === "storesGR" || catalogue === "storesInCY") {
            setCurrentStandardPrices(storesInGRStandardPrices);
            setCurrentStandardPriceNoVat(680.76);
            setCurrentStandardPriceVat(844.14);
            setCurrentFlexPrices(storesInGRFlexPrices);
            setCurrentFlexPriceNoVat(510.0);
            setCurrentFlexPriceVat(632.4);
        } else if (catalogue === "housemateCY") {
            setCurrentStandardPrices(meidanisStandardPrices);
            setCurrentStandardPriceNoVat(625.00);
            setCurrentStandardPriceVat(775.00);
            setCurrentFlexPrices(housemateCYFlexPrices);
            setCurrentFlexPriceNoVat(468.23);
            setCurrentFlexPriceVat(580.61);
        } else if (catalogue === "developers") {
            setCurrentStandardPrices(developersStandardPrices);
            setCurrentStandardPriceNoVat(850.42);
            setCurrentStandardPriceVat(1054.52);
            setCurrentFlexPrices(developersFlexPrices);
            setCurrentFlexPriceNoVat(695.03);
            setCurrentFlexPriceVat(861.84);
        } else if (catalogue === "bigProjects") {
            setCurrentStandardPrices(bigProjectsStandardPrices);
            setCurrentStandardPriceNoVat(765.38);
            setCurrentStandardPriceVat(949.07);
            setCurrentFlexPrices(bigProjectsFlexPrices);
            setCurrentFlexPriceNoVat(625.52);
            setCurrentFlexPriceVat(775.64);
        } else if (catalogue === "roBgUk") {
            setCurrentStandardPrices(meidanisStandardPrices);
            setCurrentStandardPriceNoVat(625.00);
            setCurrentStandardPriceVat(775.00);
            setCurrentFlexPrices(roBgUkFlexPrices);
            setCurrentFlexPriceNoVat(475.00);
            setCurrentFlexPriceVat(589.00);
        } else if (catalogue === "installer") {
            setCurrentStandardPrices(installerStandardPrices);
            setCurrentStandardPriceNoVat(816.13);
            setCurrentStandardPriceVat(1012);
            setCurrentFlexPrices(installerFlexPrices);
            setCurrentFlexPriceNoVat(621.00);
            setCurrentFlexPriceVat(770.04);
        } else if (catalogue === "endCustomer") {
            setCurrentStandardPrices(endCustomerStandardPrices);
            setCurrentStandardPriceNoVat(967.74);
            setCurrentStandardPriceVat(1200.00);
            setCurrentFlexPrices(endCustomerFlexPrices);
            setCurrentFlexPriceNoVat(725.00);
            setCurrentFlexPriceVat(899.00);
        }
    };

    return (
        <>
            {!nextComponentSwitch && !nextComponentModule && (
                <div className="twoPacketscontainer">
                    <img src={banner} className='banner' alt="fireSpot" />
                    <div className='twoPacketsMainContainer' style={{ paddingBottom: '30px' }}>
                        <div className='packetsContainer'>
                            <header className='twoPacketsHeader'>επιλογή πακέτου</header>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: '30px' }}>
                                <div className='switchPacketContainer'>
                                    <img className='images' src={housemateSmartPackage} alt="housemate smart home package" />
                                    <div className='packetDescription'>
                                        <div style={{ textAlign: 'center' }}>housemate smart home package</div>
                                        <div style={{ textAlign: 'center' }}>με διακόπτες</div>
                                    </div>
                                    <div className='packetsGreenButton' onClick={() => setNextComponentSwitch(true)}>
                                        <span>Ξεκινήστε εδώ</span>
                                        <ArrowSvg style={{ width: '35%', height: '100%', paddingRight: '5%' }} />
                                    </div>
                                </div>
                                <div className='modulePacketContainer'>
                                    <img className='images' src={housemateFlex} alt="housemate smart home package with modules" />
                                    <div className='packetDescription'>
                                        <div style={{ textAlign: 'center' }}>housemate smart home package</div>
                                        <div style={{ textAlign: 'center' }}>με modules[flex]</div>
                                    </div>
                                    <div className='packetsGreenButton' onClick={() => setNextComponentModule(true)}>
                                        <span>Ξεκινήστε εδώ</span>
                                        <ArrowSvg style={{ width: '35%', height: '100%', paddingRight: '5%' }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {(nextComponentSwitch || nextComponentModule) && (
                <SwitchOrModulePacket
                    nextComponentSwitch={nextComponentSwitch}
                    nextComponentModule={nextComponentModule}
                    currentStandardPrices={currentStandardPrices}
                    currentStandardPriceNoVat={currentStandardPriceNoVat}
                    currentStandardPriceVat={currentStandardPriceVat}
                    currentFlexPrices={currentFlexPrices}
                    currentFlexPriceNoVat={currentFlexPriceNoVat}
                    currentFlexPriceVat={currentFlexPriceVat}
                    clearVat={clearVat}
                    setClearVat={setClearVat}
                />
            )}
        </>
    );
}

export default SelectPacket;
