import React, { useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import PartnerSelector from './PartnerSelector';
import SelectPacket from './SelectPacket';
import SwitchOrModulePacket from './SwitchOrModulePacket';
import RouteLayout from './functions/RouteLayout';
import Login from './login';

function App() {

  const [loggedIn, setLoggedIn] = useState(false);


  return (
    <>
      <Routes>
        <Route path='/' element={<RouteLayout />} >
          {
            !loggedIn ?
              <Route index element={<Login setLoggedIn={setLoggedIn} />} />
              :
              <Route index element={<PartnerSelector loggedIn={loggedIn} />} />
          }
          <Route path="SelectPacket" element={<SelectPacket />} />
          <Route path="SwitchOrModulePacket" element={<SwitchOrModulePacket />} />
        </Route>
      </Routes>
      <span style={{ position: "fixed", top: 10, left: 10 }}>ver. {require("../package.json").version}</span>
    </>
  );
}

export default App;
