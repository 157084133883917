const endCustomerFlexPrices = {
    homeSize: { noVat: null, vat: null },
    homeLevels: { noVat: 95.16, vat: 118.0 },
    tablet: { noVat: 0.00, vat: 0.00 },
    hub: { noVat: 0.00, vat: 0.00 },
    onePhase: { noVat: 52.57, vat: 65.19 },
    threePhase: { noVat: 72.62, vat: 90.05 },
    singleSwitch: { noVat: 29.90, vat: 37.08 },
    doubleSwitch: { noVat: 35.00, vat: 43.4 },
    tripleSwitch: { noVat: 64.9, vat: 80.48 },
    howMany: { noVat: 0, vat: 0 },
    smartSocket: { noVat: 35.00, vat: 43.4 },
    dimmerSwitch: { noVat: 35.00, vat: 43.4 },
    shutersSwitch: { noVat: 35.00, vat: 43.4 },
    thermostatNumber: { noVat: 100.81, vat: 125.0 },
    boilerSwitch: { noVat: 35.00, vat: 43.4 },
    consumptionRecordSpots: { noVat: 41.71, vat: 51.72 },
    hidden_switchFrame: { noVat: 0.00, vat: 0.00 },
    hidden_consumptionRecord: { noVat: 41.71, vat: 51.72 },
    energyMetering: { noVat: 0.00, vat: 0.00 },
    singleModule: { noVat: 29.90, vat: 37.08 },
    doubleModule: { noVat: 35.00, vat: 43.4 },
    lockModule: { noVat: 35.00, vat: 43.4 },
    alarmModule: { noVat: 35.00, vat: 43.4 },
};
export default endCustomerFlexPrices;