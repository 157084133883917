const eurolampFlexPrices = {
    homeSize: { noVat: null, vat: null },
    homeLevels: { noVat: 49.76, vat: 61.70 },//
    tablet: { noVat: 0.00, vat: 0.00 },
    hub: { noVat: 0.00, vat: 0.00 },
    onePhase: { noVat: 39.48, vat: 48.96 },//
    threePhase: { noVat: 54.48, vat: 67.56 },//
    singleSwitch: { noVat: 17.65, vat: 21.89 },//
    doubleSwitch: { noVat: 20.67, vat: 25.63 },//
    tripleSwitch: { noVat: 38.32, vat: 47.52 },//
    howMany: { noVat: 0, vat: 0 },
    smartSocket: { noVat: 20.67, vat: 25.63 },//
    dimmerSwitch: { noVat: 20.67, vat: 25.63 },//
    shutersSwitch: { noVat: 20.67, vat: 25.63 },//
    thermostatNumber: { noVat: 56.97, vat: 70.64 },//
    boilerSwitch: { noVat: 20.67, vat: 25.63 },//
    consumptionRecordSpots: { noVat: 27.23, vat: 70.64 },//
    hidden_switchFrame: { noVat: 0.00, vat: 0.00 },
    hidden_consumptionRecord: { noVat: 27.23, vat: 70.64 },//
    energyMetering: { noVat: 0.00, vat: 0.00 },
    singleModule: { noVat: 17.65, vat: 21.89 },//
    doubleModule: { noVat: 20.67, vat: 25.63 },//
    lockModule: { noVat: 20.67, vat: 25.63 },//
    alarmModule: { noVat: 20.67, vat: 25.63 },//
};
export default eurolampFlexPrices;