const storesInGRFlexPrices = {
    homeSize: { noVat: null, vat: null },
    homeLevels: { noVat: 60.28, vat: 74.75 },//
    tablet: { noVat: 0.00, vat: 0.00 },
    hub: { noVat: 0.00, vat: 0.00 },
    threePhase: { noVat: 51.96, vat: 64.43 },//
    singleSwitch: { noVat: 21.39, vat: 26.52 },//
    doubleSwitch: { noVat: 25.04, vat: 31.05 },//
    tripleSwitch: { noVat: 46.43, vat: 57.57 },
    howMany: { noVat: 0, vat: 0 },
    smartSocket: { noVat: 25.04, vat: 31.05 },//
    dimmerSwitch: { noVat: 25.04, vat: 31.05 },//
    shutersSwitch: { noVat: 25.04, vat: 31.05 },//
    thermostatNumber: { noVat: 56.97, vat: 70.64 },
    boilerSwitch: { noVat: 25.04, vat: 31.05 },//
    consumptionRecordSpots: { noVat: 29.84, vat: 37.0 },
    hidden_switchFrame: { noVat: 0.00, vat: 0.00 },
    hidden_consumptionRecord: { noVat: 29.84, vat: 70.64 },
    energyMetering: { noVat: 0.00, vat: 0.00 },
    singleModule: { noVat: 21.39, vat: 26.52 },//
    doubleModule: { noVat: 25.04, vat: 31.05 },
    lockModule: { noVat: 25.04, vat: 31.05 },//
    alarmModule: { noVat: 25.04, vat: 31.05 },//
    onePhase: { noVat: 37.61, vat: 46.64 }//
};
export default storesInGRFlexPrices;