const questionsRadioButtonFlag = {

    homeLevels: false,
    energyRecord: true,
    singleSwitch: false,
    doubleSwitch: false,
    tripleSwitch: false,
    switchFrame: true,
    howMany: false,
    smartSocket: false,
    dimmerSwitch: false,
    shutersSwitch: false,
    thermostatNumber: false,
    energyRecord: true,
    consumptionRecord: true,
    consumptionRecordSpots: false,
    alarmModule: true
};
export default questionsRadioButtonFlag;