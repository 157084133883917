const roBgUkFlexPrices = {
    homeSize: { noVat: null, vat: null },
    homeLevels: { noVat: 55.35, vat: 68.63 },
    tablet: { noVat: 0.00, vat: 0.00 },
    hub: { noVat: 0.00, vat: 0.00 },
    onePhase: { noVat: 34.50, vat: 42.78 },
    threePhase: { noVat: 51.96, vat: 64.43 },
    singleSwitch: { noVat: 19.64, vat: 24.35 },
    doubleSwitch: { noVat: 22.99, vat: 28.51 },
    tripleSwitch: { noVat: 42.63, vat: 52.86 },
    howMany: { noVat: 0, vat: 0 },
    smartSocket: { noVat: 22.99, vat: 28.51 },
    dimmerSwitch: { noVat: 22.99, vat: 28.51 },
    shutersSwitch: { noVat: 22.99, vat: 28.51 },
    thermostatNumber: { noVat: 63.38, vat: 78.59 },
    boilerSwitch: { noVat: 22.99, vat: 28.51 },
    consumptionRecordSpots: { noVat: 29.84, vat: 37.00 },
    hidden_switchFrame: { noVat: 0.00, vat: 0.00 },
    hidden_consumptionRecord: { noVat: 29.84, vat: 37.00 },
    energyMetering: { noVat: 0.00, vat: 0.00 },
    singleModule: { noVat: 19.64, vat: 24.35 },
    doubleModule: { noVat: 22.99, vat: 28.51 },
    lockModule: { noVat: 22.99, vat: 28.51 },
    alarmModule: { noVat: 22.99, vat: 28.51 },
};
export default roBgUkFlexPrices;