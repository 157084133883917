import React, { useState } from 'react';
import './CSS/twoPackets.css';
import banner from './images/entranet-slider_housemate-2.jpg'
import { ReactComponent as ArrowSvg } from './images/arrow.svg'
import SelectPacket from './SelectPacket';
import { useNavigate } from 'react-router-dom';

function PartnerSelector({ loggedIn }) {



  const [hide, setHide] = useState(false);
  const [flag, setFlag] = useState(false)
  const [selectedCatalogue, setSelectedCatalogue] = useState(null);
  const navigate = useNavigate();

  const showCatalogue = (catalogue) => {
    setHide(true);
    navigate('/SelectPacket', { state: { selectedCatalogue: catalogue } });
  };



  if (!loggedIn) {
    return <></>
  }



  return (
    <>
      {!hide && (<div className="twoPacketscontainer">
        <img src={banner} className='banner' alt="fireSpot" />
        <div className='twoPacketsMainContainer' style={{ paddingBottom: '30px' }}>
          <div className='packetsContainer'>
            <header className='twoPacketsHeader'>επιλογή συνεργάτη</header>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: '30px', }}>
              <div className='switchPacketContainer'>
                <div className='packetDescription'>
                  <div style={{ textAlign: 'center' }}>Eurolamp</div>
                </div>
                <div className='packetsGreenButton' onClick={() => showCatalogue('eurolamp')}>
                  <span>Ξεκινήστε εδώ</span>
                  <ArrowSvg style={{ width: '50%', height: '100%', paddingRight: '10%' }} />
                </div>
              </div>

              <div className='modulePacketContainer'>
                <div className='packetDescription'>
                  <div style={{ textAlign: 'center' }}>Μεϊδάνης</div>
                </div>
                <div className='packetsGreenButton' onClick={() => showCatalogue('meidanis')}>
                  <span >Ξεκινήστε εδώ</span>
                  <ArrowSvg style={{ width: '50%', height: '100%', paddingRight: '10%' }} />
                </div>
              </div>

              <div className='modulePacketContainer'>
                <div className='packetDescription'>
                  <div style={{ textAlign: 'center' }}>Καταστήματα Ελλάδας</div>
                </div>
                <div className='packetsGreenButton' onClick={() => showCatalogue('storesGR')}>
                  <span >Ξεκινήστε εδώ</span>
                  <ArrowSvg style={{ width: '50%', height: '100%', paddingRight: '10%' }} />
                </div>
              </div>

              <div className='modulePacketContainer'>
                <div className='packetDescription'>
                  <div style={{ textAlign: 'center' }}>Καταστήματα Κύπρου</div>
                </div>
                <div className='packetsGreenButton' onClick={() => showCatalogue('storesInCY')}>
                  <span >Ξεκινήστε εδώ</span>
                  <ArrowSvg style={{ width: '50%', height: '100%', paddingRight: '10%' }} />
                </div>
              </div>

              <div className='modulePacketContainer'>
                <div className='packetDescription'>
                  <div style={{ textAlign: 'center' }}>Housemate Κύπρου</div>
                </div>
                <div className='packetsGreenButton' onClick={() => showCatalogue('housemateCY')}>
                  <span >Ξεκινήστε εδώ</span>
                  <ArrowSvg style={{ width: '50%', height: '100%', paddingRight: '10%' }} />
                </div>
              </div>

            </div>
            <div style={{ paddingBlock: '10px' }}></div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: '30px', }}>
              <div className='switchPacketContainer'>
                <div className='packetDescription'>
                  <div style={{ textAlign: 'center' }}>Developers</div>
                </div>
                <div className='packetsGreenButton' onClick={() => showCatalogue('developers')}>
                  <span>Ξεκινήστε εδώ</span>
                  <ArrowSvg style={{ width: '50%', height: '100%', paddingRight: '10%' }} />
                </div>
              </div>

              <div className='modulePacketContainer'>
                <div className='packetDescription'>
                  <div style={{ textAlign: 'center' }}>Big Projects</div>
                </div>
                <div className='packetsGreenButton' onClick={() => showCatalogue('bigProjects')}>
                  <span >Ξεκινήστε εδώ</span>
                  <ArrowSvg style={{ width: '50%', height: '100%', paddingRight: '10%' }} />
                </div>
              </div>

              <div className='modulePacketContainer'>
                <div className='packetDescription'>
                  <div style={{ textAlign: 'center' }}>RO-BG-UK</div>
                </div>
                <div className='packetsGreenButton' onClick={() => showCatalogue('roBgUk')}>
                  <span >Ξεκινήστε εδώ</span>
                  <ArrowSvg style={{ width: '50%', height: '100%', paddingRight: '10%' }} />
                </div>
              </div>

              <div className='modulePacketContainer'>
                <div className='packetDescription'>
                  <div style={{ textAlign: 'center' }}>Installer</div>
                </div>
                <div className='packetsGreenButton' onClick={() => showCatalogue('installer')}>
                  <span >Ξεκινήστε εδώ</span>
                  <ArrowSvg style={{ width: '50%', height: '100%', paddingRight: '10%' }} />
                </div>
              </div>

              <div className='modulePacketContainer'>
                <div className='packetDescription'>
                  <div style={{ textAlign: 'center' }}>End Customer</div>
                </div>
                <div className='packetsGreenButton' onClick={() => showCatalogue('endCustomer')}>
                  <span >Ξεκινήστε εδώ</span>
                  <ArrowSvg style={{ width: '50%', height: '100%', paddingRight: '10%' }} />
                </div>
              </div>

            </div>
          </div>


        </div>

      </div>
      )}
      {selectedCatalogue && (
        <SelectPacket selectedCatalogue={selectedCatalogue} flag={flag} />
      )}
    </>
  );

}

export default PartnerSelector;