import React, { useState } from 'react';
import './CSS/LoginForm.css';  // Import the CSS file
import { hex_md5 } from 'react-native-md5';

const Login = ({ setLoggedIn }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();

        // Basic validation
        if (!email || !password) {
            setError('Please fill in both fields');
            return;
        }

        setError('');

        const DATA = {
            method: 'POST',
            body: JSON.stringify({
                email: email + "@entranet.gr",
                name: 'Lefterhs',
                password: hex_md5(password),
            }),
            headers: { 'Content-Type': 'application/json' }

        }
        fetch('https://hm-api-v2.entranet.us:7656/main/user/login', DATA).then((res) => {
            // console.log(res);
            res.json().then((result) => {
                if (result.data?.signedToken) {
                    setLoggedIn(true);
                }
            }).catch((err) => {
                // console.log(err);
            })
            // setLoggedIn(true)
        }).catch((err) => {
            // console.log(err);
        })
        // if (email === 'admin' && password === 'admin2') {
        //     window.alert(hex_md5('900477oo'));
        //     setLoggedIn(true)
        // }
    };

    return (
        <div className="login-form-container">
            <div className="login-form">
                <h2>Login</h2>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label>Username:</label>
                        <input
                            type="username"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label>Password:</label>
                        <input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                    </div>
                    {error && <p className="error-message">{error}</p>}
                    <button type="submit" className="submit-btn">Login</button>
                </form>
            </div>
        </div>
    );
};

export default Login;
