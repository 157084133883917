const flexPacket = {
    homeLevels: 1,
    tablet: 1,
    hub: 1,
    singleSwitch: 4,
    doubleSwitch: 2,
    smartSocket: 2,
};

export default flexPacket;


