const installerFlexPrices = {
    homeSize: { noVat: null, vat: null },
    homeLevels: { noVat: 74.19, vat: 92.0 },///
    tablet: { noVat: 0.00, vat: 0.00 },
    hub: { noVat: 0.00, vat: 0.00 },
    onePhase: { noVat: 46.77, vat: 57.99 },//?
    threePhase: { noVat: 64.52, vat: 80 },//?
    singleSwitch: { noVat: 24.84, vat: 30.8 },///
    doubleSwitch: { noVat: 28.52, vat: 35.36 },///
    tripleSwitch: { noVat: 53.36, vat: 66.17 },
    howMany: { noVat: 0, vat: 0 },
    smartSocket: { noVat: 28.52, vat: 35.36 },
    dimmerSwitch: { noVat: 28.52, vat: 35.36 },
    shutersSwitch: { noVat: 28.52, vat: 35.36 },
    thermostatNumber: { noVat: 86.06, vat: 106.71 },
    boilerSwitch: { noVat: 28.52, vat: 35.36 },
    consumptionRecordSpots: { noVat: 36.29, vat: 45.0 },
    hidden_switchFrame: { noVat: 0.00, vat: 0.00 },
    hidden_consumptionRecord: { noVat: 36.29, vat: 45.0 },
    energyMetering: { noVat: 0.00, vat: 0.00 },
    singleModule: { noVat: 24.84, vat: 30.8 },///
    doubleModule: { noVat: 28.52, vat: 35.36 },///
    lockModule: { noVat: 28.52, vat: 35.36 },
    alarmModule: { noVat: 28.52, vat: 35.36 },
};
export default installerFlexPrices;