const choices = {
    homeSize: { single: '', multiple: '', singlePdf: '' },
    homeLevels: { single: 'Router', multiple: 'Routers', singlePdf: 'Router' },
    tablet: { single: 'Οθόνη έξυπνου σπιτιού', multiple: 'Οθόνες έξυνου σπιτιού', singlePdf: 'Οθόνη έξυπνου σπιτιού' },
    hub: { single: 'Hub', multiple: 'Hubs', singlePdf: 'Hub' },
    singleSwitch: { single: 'Μονό διακόπτη', multiple: 'Μονούς διακόπτες', singlePdf: 'Μονός διακόπτης' },
    doubleSwitch: { single: 'Διπλό διακόπτη', multiple: 'Διπλούς διακόπτες', singlePdf: 'Διπλός διακόπτης' },
    tripleSwitch: { single: 'Τριπλό διακόπτη', multiple: 'Τριπλούς διακόπτες', singlePdf: 'Τριπλός διακόπτης' },
    hidden_switchFrame: { single: 'Διακόπτης πλαισίου', multiple: 'Διακόπτες πλαισίου', singlePdf: 'Διακόπτης πλαισίου' },
    smartSocket: { single: 'Έξυπνη πρίζα', multiple: 'Έξυπνες πρίζες' },
    dimmerSwitch: { single: 'Διακόπτη dimmer', multiple: 'Διακόπτες dimmers', singlePdf: 'Διακόπτης dimmer' },
    shutersSwitch: { single: 'Διακόπτη χειρισμού ρολών', multiple: 'Διακόπτες χειρισμού ρολών', singlePdf: 'Διακόπτης χειρισμού ρολών' },
    thermostatNumber: { single: 'Θερμοστάτη', multiple: 'Θερμοστάτες', singlePdf: 'Θερμοστάτης' },
    boilerSwitch: { single: 'Διακόπτη boiler', multiple: 'Διακόπτες boiler' },
    lockModule: { single: 'Ηλεκτρική κλειδαριά', multiple: 'Ηλεκτρικές κλειδαριές' },
    alarmModule: { single: 'Module συναγερμού', multiple: 'Module συναγερμού' },
    onePhase: { single: 'Μονοφασικό Ρέυμα', multiple: '', singlePdf: '' },
    threePhase: { single: 'Τριφασικό Ρεύμα', multiple: '', singlePdf: '' },
    hidden_consumptionRecord: { single: 'Σημείο', multiple: 'Σημεία', singlePdf: 'Σημείο' },
    energyMetering: { single: 'Energy metering module', multiple: 'Energy metering module', singlePdf: 'Energy metering module' },
};
export default choices;